exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-performance-js": () => import("./../../../src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-projects-botanyblend-js": () => import("./../../../src/pages/projects/botanyblend.js" /* webpackChunkName: "component---src-pages-projects-botanyblend-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-listinggenie-apps-js": () => import("./../../../src/pages/projects/listinggenie-apps.js" /* webpackChunkName: "component---src-pages-projects-listinggenie-apps-js" */),
  "component---src-pages-projects-listinggenie-website-js": () => import("./../../../src/pages/projects/listinggenie-website.js" /* webpackChunkName: "component---src-pages-projects-listinggenie-website-js" */),
  "component---src-pages-projects-magicseaweed-js": () => import("./../../../src/pages/projects/magicseaweed.js" /* webpackChunkName: "component---src-pages-projects-magicseaweed-js" */),
  "component---src-pages-projects-natwest-roostermoney-js": () => import("./../../../src/pages/projects/natwest-roostermoney.js" /* webpackChunkName: "component---src-pages-projects-natwest-roostermoney-js" */),
  "component---src-pages-projects-natwest-roostermoney-performance-js": () => import("./../../../src/pages/projects/natwest-roostermoney-performance.js" /* webpackChunkName: "component---src-pages-projects-natwest-roostermoney-performance-js" */),
  "component---src-pages-projects-sama-js": () => import("./../../../src/pages/projects/sama.js" /* webpackChunkName: "component---src-pages-projects-sama-js" */),
  "component---src-pages-projects-superyachttenders-js": () => import("./../../../src/pages/projects/superyachttenders.js" /* webpackChunkName: "component---src-pages-projects-superyachttenders-js" */),
  "component---src-pages-projects-willpower-fitness-js": () => import("./../../../src/pages/projects/willpower-fitness.js" /* webpackChunkName: "component---src-pages-projects-willpower-fitness-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

