// extracted by mini-css-extract-plugin
export var backgroundGreyLightest = "theme-module--backgroundGreyLightest--271e6";
export var theme1 = "theme-module--theme-1--f4aa1";
export var theme1Color = "theme-module--theme-1-color--f5a87";
export var theme2 = "theme-module--theme-2--2a26f";
export var theme2Color = "theme-module--theme-2-color--604a3";
export var theme3 = "theme-module--theme-3--652f4";
export var theme3Color = "theme-module--theme-3-color--130db";
export var theme4 = "theme-module--theme-4--3f2ae";
export var theme4Color = "theme-module--theme-4-color--dc04e";
export var theme5 = "theme-module--theme-5--29574";
export var theme5Color = "theme-module--theme-5-color--8e98d";